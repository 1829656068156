/* reset css*/
* {
  font-family: $font-family-base;
  color: $grey-main;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
*:focus {
  outline: none !important;
  box-shadow: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: $black;
  box-shadow: 0 0 0px 1000px $white inset;
  transition: background-color 5000s ease-in-out 0s;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
ol,
ul {
  list-style: none;
  margin-bottom: 0;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
a:hover,
.btn.focus {
  outline: 0;
  box-shadow: none;
  text-decoration: none;
}
pre {
  font-family: $font-family-base;
  tab-size: 2;
}
html {
  font-size: $reset-rem-size;
}
