//AG Grid css

.ag-theme-alpine .ag-header {
  background-color: #bfbff5;
}

.ag-theme-alpine .ag-header-cell-text {
  color: #0f0fd1;
}

.ag-header-cell-text {
  text-overflow: clip !important;
  overflow: visible !important;
  white-space: normal !important;
}

.ag-theme-alpine .ag-root-wrapper {
  border-radius: 5px;
  border-color: #e9e9f5;
}
.ag-theme-alpine .ag-header-cell-resize::after {
  background-color: #537fc2;
}
.ag-theme-alpine .ag-pinned-left-header {
  border-right-color: #537fc2;
}
.ag-popup-child {
  z-index: 100;
}
.ag-theme-alpine .ag-ltr .ag-cell {
  border-right: 0.1px solid #e9e9f5;
  // height: auto;
  // border-bottom: 0.5px solid #999ea6;
}

.ag-row-odd {
  background-color: aliceblue !important;
}
.ag-theme-alpine .ag-overlay-loading-center {
  border: none !important;
  display: none;
}
.custom-loader:before {
  position: absolute;
  content: "";
  top: 0%;
  left: 0%;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  border-radius: 0.28571429rem;
  z-index: 100;
}

.custom-loader:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -1.5em 0em 0em -1.5em;
  width: 5em;
  height: 5em;
  background-image: url("../../../assets/images/aggrid-spinner.gif");
  visibility: visible;
  z-index: 101;
}
.ag-theme-alpine .ag-row-selected {
  background-color: rgba(220, 53, 69, 0.3) !important; /* red */
}
