/* import all style files */

// helper files
@import "./helpers/variables";
@import "./helpers/mixins";
@import "./helpers/flex";
@import "./helpers/responsive";

// base files
@import "./base/reset";
@import "./base/base";

// material files
@import "./material/material-theme-custom";
@import "./material/material-custom";

// utilities
@import "./utilities/utilities";

// components
@import "components/common";
@import "components/welcome-page";
@import "components/menu-card";
@import "components/table";
@import "components/breadcrumb";
@import "components/sidepanel-model";
@import "components/tree-basic";
@import "components/chart";
@import "components/grid";
@import "components/ng-zorro";
@import "components/aggrid";
