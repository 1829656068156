.breadcrumb {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  .breadcrumb-item {
    & + .breadcrumb-item {
      &:before {
        content: "/";
        font-size: 12px;
        line-height: 14px;
        color: $text-color-light;
        display: inline-block;
        margin: 0 5px;
      }
    }
  }
  .breadcrumb-link {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $text-color-light;
  }
}
