.chart-group {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 0 -30px;
  .chart-items {
    margin: 0 0 30px 30px;
    padding-left: 30px;
    padding-right: 30px;
    width: calc(50% - 30px);
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding-bottom: 20px;
  }
  &.chart-group-top-margin {
    margin: 0 0 0 -30px;
  }
  .chart-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #1d252c;
    margin-top: 26px;
    margin-bottom: 30px;
  }
}
