@import "../helpers/variables";

.sidepanel-model-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  @include transition(0.4);
  z-index: 100;
  .sidepanel-close {
    position: absolute;
    min-width: 100vw;
    min-height: 100vh;
    background: rgba($black, 0.2);
  }
  .model-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    min-width: 500px;
    background: $white;
    padding: 30px;
    overflow-x: hidden;
    transform: translateX(100%);
    @include transition(0.4);
  }
  .model-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .model-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: $grey-main;
  }
  .form-group {
    .mat-form-field {
      display: block;
    }
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
  .model-form-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    .mat-button-base {
      margin-left: 5px;
    }
  }
  &.active {
    opacity: 1;
    visibility: visible;
   // z-index: 500 !important;
    .model-content {
      transform: translateX(0);
    }
  }
}
