// base font family
$font-family-base: Roboto, "Helvetica Neue", sans-serif;
$reset-rem-size: 16px;
$font-size-base: 0.7rem;

// color
$white: #fff;
$black: #000;
$main-bg: #f9faff;

$primary-color-1: #0f0fd1;
$primary-color-2: #10ce9c;

$secondary-color-1: #05057c;
$secondary-color-2: #040496;
$secondary-color-3: #f85439;
$secondary-color-4: #824aed;
$secondary-color-5: #3aafe4;
$secondary-color-6: #1d252c;
$secondary-color-7: #323e48;
$secondary-color-8: #5b6670;
$secondary-color-9: #7b868c;
$secondary-color-10: #a2a9ad;
$secondary-color-11: #c1c5c8;
$secondary-color-12: #cfd2d3;

$grey-main: #1d252c;
$grey-2: #323e48;
$paragraph-color: #7d86a5;
$text-color-light: #7b868c;
$main-border-color: #ced4da;
$main-border-light-color: #e8eaef;

// text color
$disabled-text-color: #9c9c9c;

// btn style
$btn-bg-primary: #0ab375;
$btn-bg-secondary: #e63956;
$btn-border: #c9d0da;

// form elements
$label-color: #10123d;
$placeholder-color: #7b868c;
$search-form-placeholder: #c2cadb;

// alert
$alert-success-cololr: #155724;
$alert-success-bg-cololr: #d4edda;
$alert-success-br-cololr: #c3e6cb;
$alert-info-cololr: #0c5460;
$alert-info-bg-cololr: #d1ecf1;
$alert-info-br-cololr: #bee5eb;
$alert-warning-cololr: #856404;
$alert-warning-bg-cololr: #fff3cd;
$alert-warning-br-cololr: #ffeeba;
$alert-danger-cololr: #721c24;
$alert-danger-bg-cololr: #f8d7da;
$alert-danger-br-cololr: #f5c6cb;

// border radius
$border-radius-main: 4px;
$border-radius-medium: 8px;
$border-radius-big: 15px;

// sidebar menu
$sidebar-menu-width-open: 355px;
$sidebar-menu-width-closed: 86px;
$sidebar-menu-bg-color: #3333d6;
$sidebar-menu-text-color: #c2c2e8;

$disabled-icon-color: #cacaca;
$tree-line-color: #c1c5c8;

// table
$th-bg-color: #e0e0ff;
$tr-hover-bg: #f4f6fd;
